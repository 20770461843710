<!--数据-->
<template>
  <div class="container data-box">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-left">
          <div class="img">
            <img src="../assets/img/img-n15.png" alt=""/>
          </div>
          固废资源化技术分类查询
        </h1>
      </div>
    </div>
    <div class="classify-cont">
      <div class="row">
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">固废来源</div>
            <div class="t1">
              <img src="../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content1"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities1"
                  @change="handleCheckedCitiesChange1"
              >
                <el-checkbox
                    v-for="(city, index) in options.wasteSource"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(1)">
                全部选中
              </div>
              <div @click="eliminate(1)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities1"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 1)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">固废大类</div>
            <div class="t1">
              <img src="../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content2"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities2"
                  @change="handleCheckedCitiesChange2"
              >
                <el-checkbox
                    v-for="(city, index) in options.wasteBigType"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(2)">
                全部选中
              </div>
              <div @click="eliminate(2)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities2"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 2)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">固废小类</div>
            <div class="t1">
              <img src="../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content3"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities3"
                  @change="handleCheckedCitiesChange3"
              >
                <el-checkbox
                    v-for="(city, index) in options.wasteSmallType"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(3)">
                全部选中
              </div>
              <div @click="eliminate(3)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities3"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 3)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">资源化产品大类</div>
            <div class="t1">
              <img src="../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content4"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities4"
                  @change="handleCheckedCitiesChange4"
              >
                <el-checkbox
                    v-for="(city, index) in options.productBigType"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(4)">
                全部选中
              </div>
              <div @click="eliminate(4)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities4"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 4)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">资源化产品小类</div>
            <div class="t1">
              <img src="../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content5"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities5"
                  @change="handleCheckedCitiesChange5"
              >
                <el-checkbox
                    v-for="(city, index) in options.productSmallType"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(5)">
                全部选中
              </div>
              <div @click="eliminate(5)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities5"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 5)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">模糊查询</div>
            <div class="t1">
              <img src="../assets/img/img-n22.png" alt=""/>
              <input
                  placeholder="输入搜索内容"
                  v-model="searchModel.searchValue"
              />
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="query-but" @click="selectData">
            <img src="../assets/img/img-n22.png" alt=""/>查询结果
          </div>
        </div>
      </div>
    </div>
    <!-- -------- -->
    <!-- <b-card class="col-ms-12 filter" bg-variant="light">
      <b-form-group label="固废来源" label-cols-sm="5" label-align-sm="right">
        <el-select
          v-model="searchModel.sources"
          multiple
          collapse-tags
          placeholder="请选择"
          @change="queryWasteBigSelect"
        >
          <el-option
            v-for="item in options.wasteSource"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </b-form-group>
      <b-form-group label="固废大类" label-cols-sm="5" label-align-sm="right">
        <el-select
          v-model="searchModel.wasteBigTypes"
          multiple
          collapse-tags
          placeholder="请选择"
          @change="queryWasteSmallSelect"
        >
          <el-option
            v-for="item in options.wasteBigType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </b-form-group>
      <b-form-group label="固废小类" label-cols-sm="5" label-align-sm="right">
        <el-select
          v-model="searchModel.wasteSmallTypes"
          multiple
          collapse-tags
          placeholder="请选择"
        >
          <el-option
            v-for="item in options.wasteSmallType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </b-form-group>
      <b-form-group
        label="资源化产品大类"
        label-cols-sm="5"
        label-align-sm="right"
      >
        <el-select
          v-model="searchModel.productBigTypes"
          multiple
          collapse-tags
          placeholder="请选择"
          @change="queryProductSmallSelect"
        >
          <el-option
            v-for="item in options.productBigType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </b-form-group>
      <b-form-group
        label="资源化产品小类"
        label-cols-sm="5"
        label-align-sm="right"
      >
        <el-select
          v-model="searchModel.productSmallTypes"
          multiple
          collapse-tags
          placeholder="请选择"
        >
          <el-option
            v-for="item in options.productSmallType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </b-form-group>
      <b-form-group label="模糊查询" label-cols-sm="5" label-align-sm="right">
        <el-input
          placeholder="请输入内容"
          v-model="searchModel.searchValue"
          clearable
        >
        </el-input>
      </b-form-group>
      <div class="search el-col-md-24">
        <el-button type="primary" @click="selectData">查 询</el-button>
      </div>
    </b-card> -->
    <!-- ------------------ -->
    <b-card class="col-ms-12 search-data" header="查询结果">
      <el-table :data="table.tableData" style="width: 100%" max-height="500">
        <!--        <el-table-column
                    type="index"
                    label="序号"
                    width="50">
                </el-table-column>-->
        <el-table-column prop="technologyType" label="技术类型" width="300">
        </el-table-column>
        <el-table-column prop="technologyCode" label="技术分类编码" width="350">
        </el-table-column>
        <el-table-column prop="technologyOutline" label="说明" width="350">
        </el-table-column>
        <el-table-column prop="source" label="固废来源" width="350">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="queryCase(scope.$index, table.tableData)"
                type="text"
                size="small"
            >
              案例（{{table.tableData[scope.$index].caseCount}}）
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          layout="total, prev, pager, next"
          :total="table.total"
          :page-count="table.pageCount"
          :page-size="table.pageSizes"
          :current-page="table.currentPage"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </b-card>

    <b-card class="col-ms-12 use-data" header="使用说明">{{ this.instructions}}</b-card>

    <el-dialog
        title="技术案例"
        :visible.sync="caseDialogVisible"
        width="60%">
      <el-table
          :data="caseTableData"
          style="width: 100%">
        <el-table-column
            prop="techName"
            label="技术名称"
            width="350">
        </el-table-column>
        <el-table-column
            prop="fillingUnit"
            label="填报单位"
            width="250">
        </el-table-column>
        <el-table-column
            prop="personLiable"
            label="填报人"
            width="100">
        </el-table-column>
        <el-table-column
            prop="unitAddress"
            label="单位地址"
            width="250">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button @click="viewDetail(scope.row.id)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="详情" :visible.sync="detailVisible" width="60%" class="detailDialog">
      <el-row :gutter="20">
        <el-col :span="6">
          技术名称: <span>{{ this.dialogTechData.techName }}</span>
        </el-col>
        <el-col :span="6">
          信息采集负责人: <span>{{ this.dialogTechData.personLiable }}</span>
        </el-col>
        <el-col :span="6">
          联系方式: <span>{{ this.dialogTechData.applicationUnit }}</span>
        </el-col>
        <el-col :span="6">
          填报单位: <span>{{ this.dialogTechData.fillingUnit }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          技术应用单位地址: <span>{{ this.dialogTechData.unitAddress }}</span>
        </el-col>
        <el-col :span="6">
          填报时间: <span>{{ this.dialogTechData.fillingTime }}</span>
        </el-col>
        <el-col :span="6">
          项目投产时间:<span> {{ this.dialogTechData.productionTime }}</span>
        </el-col>
        <el-col :span="6">
          数据来源: <span> {{ this.dialogTechData.source }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          固废大类: <span> {{ this.dialogTechData.wasteType }}</span>
        </el-col>
        <el-col :span="6">
          固废小类: <span> {{ this.dialogTechData.wasteSubtype }}</span>
        </el-col>
        <el-col :span="6">
          资源化产品大类: <span>{{ this.dialogTechData.resourceType }}</span>
        </el-col>
        <el-col :span="6">
          资源化产品小类:<span> {{ this.dialogTechData.resourceSubtype }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          技术就绪度自评: <span>{{ this.dialogTechData.techReadiness }}</span>
        </el-col>
        <el-col :span="6">
          年固废处理规模(吨): <span>{{ this.dialogTechData.treatmentScale }}</span>
        </el-col>
        <el-col :span="6">
          年处理固废总量(吨): <span>{{ this.dialogTechData.treatmentTotal }}</span>
        </el-col>
        <el-col :span="6">
          新鲜水（自来水）消耗量(立方米/年): <span>{{ this.dialogTechData.tapWasterConsumption }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          项目占地面积(平方米): <span>{{ this.dialogTechData.areaCovered }}</span>
        </el-col>
        <el-col :span="6">
          电消耗量(千瓦时/年):<span> {{ this.dialogTechData.electricityConsumption }}</span>
        </el-col>
        <el-col :span="6">
          原煤消耗量(吨/年): <span>{{ this.dialogTechData.coalConsumption }}</span>
        </el-col>
        <el-col :span="6">
          天然气消耗量(m3):<span> {{ this.dialogTechData.naturalGasConsumption }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          蒸汽消耗量(立方米/年):<span> {{ this.dialogTechData.steamConsumption }}</span>
        </el-col>
        <el-col :span="6">
          柴油消耗量(吨/年): <span> {{ this.dialogTechData.dieselConsumption }}</span>
        </el-col>
        <el-col :span="6">
          汽油消耗(吨/年): <span> {{ this.dialogTechData.oilConsumption }}</span>
        </el-col>
        <el-col :span="6">
          进入到资源化生产过程中的固废量(吨/年): <span>{{ this.dialogTechData.resourceWaste }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          甲烷产生量(立方米/年): <span> {{ this.dialogTechData.copperProduction }}</span>
        </el-col>
        <el-col :span="6">
          循环水利用量(吨): <span>{{ this.dialogTechData.circulatingWaterConsumption }}</span>
        </el-col>
        <el-col :span="6">
          余热利用量(W): <span>{{ this.dialogTechData.wasteHeatRecovery }}</span>
        </el-col>
        <el-col :span="6">
          废气年排放总量(立方米/年): <span>{{ this.dialogTechData.gasTotal }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          二氧化硫年排放总量(吨/年): <span>{{ this.dialogTechData.so2Total }}</span>
        </el-col>
        <el-col :span="6">
          氮氧化物年排放总量(吨/年): <span>{{ this.dialogTechData.noxTotal }}</span>
        </el-col>
        <el-col :span="6">
          废水年排放总量(立方米/年): <span>{{ this.dialogTechData.sewageTotal }}</span>
        </el-col>
        <el-col :span="6">
          化学需氧量（COD)年排放总量(吨/年): <span>{{ this.dialogTechData.codTotal }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          化学需氧量（BOD)年排放总量(吨/年): <span>{{ this.dialogTechData.bodTotal }}</span>
        </el-col>
        <el-col :span="6">
          氨氮（NH3-N)年排放总量(吨/年): <span>{{ this.dialogTechData.nh3nTotal }}</span>
        </el-col>
        <el-col :span="6">
          总磷（P)年排放总量(吨/年): <span>{{ this.dialogTechData.phosphorusTotal }}</span>
        </el-col>
        <el-col :span="6">
          固废（废渣）年排放总量(吨/年): <span>{{ this.dialogTechData.wasteDischarge }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          总投资建设费用(万元): <span>{{ this.dialogTechData.constructionCost }}</span>
        </el-col>
        <el-col :span="6">
          年运行成本(万元/年): <span>{{ this.dialogTechData.operatingCost }}</span>
        </el-col>
        <el-col :span="6">
          销售收入(万元/年): <span>{{ this.dialogTechData.salesRevenue }}</span>
        </el-col>
        <el-col :span="6">
          净利润(万元/年): <span>{{ this.dialogTechData.netProfit }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          净现值(万元):<span> {{ this.dialogTechData.netPresentValue }}</span>
        </el-col>
        <el-col :span="6">
          专利价值(万元):<span> {{ this.dialogTechData.patentValue }}</span>
        </el-col>
        <el-col :span="6">
          提供就业岗位数(个):<span> {{ this.dialogTechData.offerJobTotal }}</span>
        </el-col>
        <el-col :span="6">
          循环经济示范性(是/否): <span>{{ this.dialogTechData.circularEconomy |formatBool }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          职工可能暴露在腐蚀/有毒化学品环境下:<span> {{ this.dialogTechData.inToxicCorrosion |formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工可能暴露在泼溅条件下: <span>{{ this.dialogTechData.inHighSpeed |formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工可能暴露在高温表面下: <span>{{ this.dialogTechData.inHighTemperature |formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工可能暴露在高速部件下:<span> {{ this.dialogTechData.inSplash |formatBool }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          职工可能暴露在高压电下: <span>{{ this.dialogTechData.inHighVoltage |formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工可能暴露在高分贝噪声下: <span>{{ this.dialogTechData.inHighDecibel |formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工可能暴露在难闻气味下:<span> {{ this.dialogTechData.inOdorousGas | formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工可能暴露在充满灰尘工作条件下: <span>{{ this.dialogTechData.inDust | formatBool }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          公众对技术实施的态度: <span>{{ this.dialogTechData.publicRecognition | formatBool }}</span>
        </el-col>
        <el-col :span="6">
          职工平均工资(万元/年): <span>{{ this.dialogTechData.avgWage }}</span>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
                <!--        <el-button @click="view.dialogVisible = false">关 闭</el-button>-->
            </span>
    </el-dialog>
  </div>
</template>
<script>
import ui from "@/libs/ui";
import {
  getWasteSourceSelect,
  getWasteBigTypeSelect,
  getWasteSmallTypeSelect,
  getProductBigSelect,
  getProductSmallSelect,
} from "@/api/common";
import {getCase, queryPage} from "@/api/product";
import {
  queryTechDetail
} from "@/api/waste";

export default {
  name: "Classification",
  props: {
    msg: String,
  },
  data() {
    return {
      caseDialogVisible: false,
      caseTableData: [],
      detailVisible: false,
      dialogTechData: {},
      checkedCities1: [],
      checkedCities2: [],
      checkedCities3: [],
      checkedCities4: [],
      checkedCities5: [],

      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      checkedCities: [],
      cities: ["上海", "北京", "广州", "深圳"],
      dynamicTags: ["上海", "北京", "广州", "深圳"],
      searchModel: {
        searchValue: "",
        sources: [],
        wasteBigTypes: [],
        wasteSmallTypes: [],
        productBigTypes: [],
        productSmallTypes: [],
      },
      options: {
        wasteSourceFil: [],
        wasteSource: [],
        wasteBigTypeFil: [],
        wasteBigType: [],
        wasteSmallTypeFil: [],
        wasteSmallType: [],
        productBigTypeFil: [],
        productBigType: [],
        productSmallTypeFil: [],
        productSmallType: [],
      },
      table: {
        tableData: [],
        total: 0,
        currentPage: 1,
        pageCount: 0,
        pageSizes: 20,
      },

      instructions: ''
    };
  },
  watch: {
    content1(val) {
      this.options.wasteSource = this.options.wasteSourceFil.filter((p) => {
        return p.label.indexOf(val) !== -1;
      });
    },
    content2(val) {
      this.options.wasteBigType = this.options.wasteBigTypeFil.filter((p) => {
        return p.label.indexOf(val) !== -1;
      });
    },
    content3(val) {
      this.options.wasteSmallType = this.options.wasteSmallTypeFil.filter(
          (p) => {
            return p.label.indexOf(val) !== -1;
          }
      );
    },
    content4(val) {
      this.options.productBigType = this.options.productBigTypeFil.filter(
          (p) => {
            return p.label.indexOf(val) !== -1;
          }
      );
    },
    content5(val) {
      this.options.productSmallType = this.options.productSmallTypeFil.filter(
          (p) => {
            return p.label.indexOf(val) !== -1;
          }
      );
    },
  },
  mounted() {
    this.init();
  },
  filters: {
    formatBool(val) {
      let rtl
      if (val === 0) {
        rtl = '否'
      } else {
        rtl = '是'
      }
      return rtl
    }
  },
  methods: {
    handleData(data1, data2) {
      let labelArr = [];
      data1.forEach((item) => {
        data2.forEach((item2) => {
          if (item.label == item2) {
            labelArr.push(item.value);
          }
        });
      });
      return labelArr;
    },
    handleCheckedCitiesChange1(val) {
      this.searchModel.sources.length = 0
      val.forEach(x => {
        this.searchModel.sources.push(x)
      })
      this.queryWasteBigSelect();
    },
    handleCheckedCitiesChange2(val) {
      this.searchModel.wasteBigTypes = this.handleData(
          this.options.wasteBigType,
          val
      );
      this.queryWasteSmallSelect();
    },
    handleCheckedCitiesChange3(val) {
      this.searchModel.wasteSmallTypes = this.handleData(
          this.options.wasteSmallType,
          val
      );
    },
    handleCheckedCitiesChange4(val) {
      this.searchModel.productBigTypes = this.handleData(
          this.options.productBigType,
          val
      );
      this.queryProductSmallSelect();
    },
    handleCheckedCitiesChange5(val) {
      this.searchModel.productSmallTypes = this.handleData(
          this.options.productSmallType,
          val
      );
    },
    //全选
    handleCheckAllChange(n) {
      if (n == 1) {
        this.checkedCities1 = [];
        this.options.wasteSource.forEach((item) => {
          this.checkedCities1.push(item.label);
          this.searchModel.sources.push(item.label)
        });
        this.queryWasteBigSelect();
      }
      if (n == 2) {
        this.checkedCities2 = [];
        this.options.wasteBigType.forEach((item) => {
          this.checkedCities2.push(item.label);
          // this.searchModel.wasteBigTypes = this.checkedCities2;

        });
        this.searchModel.wasteBigTypes = this.handleData(
            this.options.wasteBigType,
            this.checkedCities2
        );
        this.queryWasteSmallSelect();
      }
      if (n == 3) {
        this.checkedCities3 = [];
        this.options.wasteSmallType.forEach((item) => {
          this.checkedCities3.push(item.label);
        });

        this.searchModel.wasteSmallTypes = this.handleData(
            this.options.wasteSmallType,
            this.checkedCities3
        )
      }
      if (n == 4) {
        this.checkedCities4 = [];
        this.options.productBigType.forEach((item) => {
          this.checkedCities4.push(item.label);
        });
        this.searchModel.productBigTypes = this.handleData(
            this.options.productBigType,
            this.checkedCities4
        );
        this.queryProductSmallSelect();
      }
      if (n == 5) {
        this.checkedCities5 = [];
        this.options.productSmallType.forEach((item) => {
          this.checkedCities5.push(item.label);
        });
        this.searchModel.productSmallTypes = this.handleData(
            this.options.productSmallType,
            this.checkedCities5
        );
      }
    },
    //清除选择
    eliminate(n) {
      if (n == 1) {
        this.checkedCities1 = [];
        this.searchModel.sources = []
        this.queryWasteBigSelect()
      }
      if (n == 2) {
        this.checkedCities2 = [];
        this.searchModel.wasteBigTypes = []
        this.queryWasteSmallSelect()
      }
      if (n == 3) {
        this.checkedCities3 = [];
        this.searchModel.wasteSmallTypes = []
      }
      if (n == 4) {
        this.checkedCities4 = [];
        this.searchModel.productBigTypes = []
        this.queryProductSmallSelect()
      }
      if (n == 5) {
        this.checkedCities5 = [];
        this.searchModel.productSmallTypes = []
      }
    },
    //清除选择  底部文字
    handleClose(tag, n) {
      if (n == 1) {
        this.checkedCities1.splice(this.checkedCities1.indexOf(tag), 1);
        this.searchModel.sources.splice(
            this.searchModel.sources.indexOf(tag),
            1
        );
        this.queryWasteBigSelect();
      }
      if (n == 2) {
        this.checkedCities2.splice(this.checkedCities2.indexOf(tag), 1);
        this.searchModel.wasteBigTypes.splice(
            this.searchModel.wasteBigTypes.indexOf(tag),
            1
        );
        this.queryWasteSmallSelect()
      }
      if (n == 3) {
        this.checkedCities3.splice(this.checkedCities3.indexOf(tag), 1);

        this.searchModel.wasteSmallTypes.splice(
            this.searchModel.wasteSmallTypes.indexOf(tag),
            1
        );
      }
      if (n == 4) {
        this.checkedCities4.splice(this.checkedCities4.indexOf(tag), 1);
        this.searchModel.productBigTypes.splice(
            this.searchModel.productBigTypes.indexOf(tag),
            1
        );
        this.queryProductSmallSelect()
      }
      if (n == 5) {
        this.checkedCities5.splice(this.checkedCities5.indexOf(tag), 1);
        this.searchModel.productSmallTypes.splice(
            this.searchModel.productSmallTypes.indexOf(tag),
            1
        );
      }
    },
    init() {
      this.queryWasteSourceSelect();
      this.queryProductBigSelect();
      this.selectData();
      this.queryInstructions()
    },
    queryInstructions(){
      let item = window.localStorage.getItem("instructions")
      this.instructions = JSON.parse(item)['technical_classification']
    },
    queryWasteSourceSelect() {
      getWasteSourceSelect().then((data) => {
        this.options.wasteSource = data;
        this.options.wasteSourceFil = data;
      });
    },
    queryWasteBigSelect() {
      if (this.searchModel.sources.length === 0) {
        this.options.wasteBigType.length = 0
        return
      }
      getWasteBigTypeSelect(this.searchModel.sources).then((data) => {
        this.options.wasteBigType = data;
        this.options.wasteBigTypeFil = data;
      });
    },
    queryWasteSmallSelect() {
      if (this.searchModel.wasteBigTypes.length === 0) {
        this.options.wasteSmallType.length = 0
        return
      }
      getWasteSmallTypeSelect(this.searchModel.wasteBigTypes).then((data) => {
        this.options.wasteSmallType = data;
        this.options.wasteSmallTypeFil = data;
      });
    },
    queryProductBigSelect() {
      getProductBigSelect().then((data) => {
        this.options.productBigType = data;
        this.options.productBigTypeFil = data;
      });
    },
    queryProductSmallSelect() {
      if (this.searchModel.productBigTypes.length === 0) return;
      getProductSmallSelect(
          this.searchModel.wasteSmallTypes,
          this.searchModel.productBigTypes
      ).then((data) => {
        this.options.productSmallType = data;
        this.options.productSmallTypeFil = data;
      });
    },
    queryTableData(searchParams, page) {
      if (!searchParams) {
        searchParams = this.searchModel;
      }
      queryPage(searchParams, page).then((data) => {
        this.table.tableData = data.records;
        this.table.total = data.total;
        this.table.currentPage = data.current;
        this.table.pageSizes = data.size;
        this.table.pageCount = data.pages;
      });
    },
    selectData() {
      this.queryTableData();
    },
    handleCurrentChange(val) {
      const page = {
        pageNo: val,
        pageSize: this.table.pageSizes,
      };
      this.queryTableData(this.searchModel, page);
    },
    queryCase(index) {
      let techName = this.table.tableData[index].technologyType

      getCase({techName}).then(data => {
        this.caseTableData = data
        this.caseDialogVisible = true
      })
    },
    viewDetail(id) {
      if (id === null || id === undefined || id === '') {
        return
      }
      queryTechDetail(id).then(data => {
        this.dialogTechData = data
        this.detailVisible = true
      })
    },
    toSearch() {
      // eslint-disable-next-line no-debugger
      // debugger
      if (this.keyword === "") {
        ui.toast(this, ui.variant.primary, "请输入搜索内容");
        return;
      }
      if (this.$store.getters.token === "") {
        ui.toast(this, ui.variant.primary, "请先登录账号");
        return;
      }
      this.$router.push("/search/" + this.keyword);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../assets/less/data.less";
</style>